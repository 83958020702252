import React, {useState} from "react"
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import {
    CREATE_SERVICE_CLICKANDMEET_MUTATION,
    UPDATE_TENANT_CLICKANDMEET_MUTATION
} from "../../graphql/clickAndMeetGraphQL";
import {useMutation} from "@apollo/client";
import {navigate} from "gatsby";
import {useTranslation} from "react-i18next";

import logo from "../../images/logo.png";
import dancingVideo from "../../videos/dancing.mp4";

function removeItemOnce(arr, value) {
    let index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}

export default function Services(prop) {

    const { t } = useTranslation()

    const MAX_NUMBER_SERVICES = 5 // more then 5 services not allowed
    const MIN_CHARACTERS_SERVICE = 3 // strings less then 3 characters not allowed
    const ERROR_DISPLAY_TIME = 5000 // 5 seconds

    const [
        createService,
        {loading: mutationLoadingCreateService, error: mutationErrorCreateService},
    ] = useMutation(CREATE_SERVICE_CLICKANDMEET_MUTATION)

    const [
        updateTenant,
        {loading: mutationLoadingUpdateTenant, error: mutationErrorUpdateTenant},
    ] = useMutation(UPDATE_TENANT_CLICKANDMEET_MUTATION)


    const [service, setService] = useState("")
    const [services, setServices] = useState([])
    const [validationMessage, setValidationMessage] = useState("")

    const handleServices = async (event) => {
        // Block native form submission.
        event.preventDefault()

        if (services.length > 0) {

            try {
                let servicesId = []
                // eslint-disable-next-line no-undef
                await Promise.all(services.map(name => {
                    return createService({
                        variables: {
                            name: name,
                        },
                    }).then((res) => {
                        console.log("Create Service: ", res)
                        servicesId.push(res.data.createService.service.id)
                    })
                }));

                updateTenant({
                    variables: {
                        tenantId: prop.location.state.tenant.id,
                        title: prop.location.state.tenant.Module[0].Title,
                        description: prop.location.state.tenant.Module[0].Description,
                        firstSlot: prop.location.state.tenant.Module[0].FirstSlot,
                        lastSlot: prop.location.state.tenant.Module[0].LastSlot,
                        slots: prop.location.state.tenant.Module[0].Slots,
                        interval: prop.location.state.tenant.Module[0].Intervall,
                        workdays: prop.location.state.tenant.Module[0].Workdays,
                        services: servicesId
                    },
                }).then((res) => {
                    navigate("/meet/members", {
                        state: { tenant: res.data.updateTenant.tenant },
                    })
                })
            } catch (e) {
                console.log(e)
            }

        } else {
            setValidationMessage(t("meet.setup.validation.add.service" ))
            setTimeout(function(){
                setValidationMessage("")
            }.bind(this),ERROR_DISPLAY_TIME);
        }
    }

    const addService = () => {
        if (service.length > MIN_CHARACTERS_SERVICE) {
            if (services.length < MAX_NUMBER_SERVICES) {
                setServices([...services, service])
                setService("")
                setValidationMessage("")
            } else {
                setValidationMessage(t("meet.setup.validation.max.services", { max_number_services: MAX_NUMBER_SERVICES} ))
                setTimeout(function(){
                    setValidationMessage("")
                }.bind(this),ERROR_DISPLAY_TIME);
            }
        } else {
            setValidationMessage(t("meet.setup.validation.min.characters", { min_characters_service: MIN_CHARACTERS_SERVICE}))
            setTimeout(function(){
                setValidationMessage("")
            }.bind(this),ERROR_DISPLAY_TIME);
        }
    }

    const removeService = (serviceName) => {
       setServices([...removeItemOnce(services, serviceName)])
    }

    if (mutationLoadingCreateService) {
        console.log(mutationLoadingCreateService)
    }

    if (mutationLoadingUpdateTenant) {
        console.log(mutationLoadingUpdateTenant)
    }

    if (mutationErrorCreateService != undefined) {
        console.log(mutationErrorCreateService)
    }

    if (mutationErrorUpdateTenant != undefined) {
        console.log(mutationErrorUpdateTenant)
    }

    return(
        <div className="min-h-screen bg-white flex">
            <div className="flex-1 flex flex-col py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <img
                            className="h-12 w-auto"
                            src={logo}
                            alt="ClickAnd"
                        />
                        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                            {t('meet.service.title')}
                        </h2>
                        <p className="mt-2 text-sm text-gray-600">
                            {t('meet.service.subtitle', { max_number_services: MAX_NUMBER_SERVICES})}
                        </p>
                    </div>

                    <div className="mt-8">

                        <div className="mt-6">
                            <form
                                onSubmit={handleServices}
                                className="space-y-6">
                                <div>
                                    <label htmlFor="service" className="block text-sm font-medium text-gray-700">
                                        {t('meet.service.input.service')}
                                    </label>
                                    <div className="mt-1 flex rounded-md shadow-sm">
                                        <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                            <input
                                                type="text"
                                                name="service"
                                                id="service"
                                                value={service}
                                                minLength={MIN_CHARACTERS_SERVICE}
                                                onChange={(e) => {
                                                    setService(e.target.value)
                                                }}
                                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                                                placeholder={t('meet.service.input.service.placeholder')}
                                            />
                                            {validationMessage && (
                                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                </div>
                                            )}
                                        </div>
                                        <div onClick={addService} className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                                            <span>{t('meet.service.button.service.add')}</span>
                                        </div>
                                    </div>
                                    <p className="mt-1 text-sm text-red-600" id="error">
                                        {validationMessage}
                                    </p>
                                </div>

                                <div>
                                    {services.map((ser, index) => (

                                        <span key={index}
                                              className="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-indigo-100 text-indigo-700 ml-1 mb-1">
                                            {ser}
                                            <div
                                                onClick={() => removeService(ser)}
                                                type="button"
                                                className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
                                            >
                                                <span className="sr-only">Remove large option</span>
                                                <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                    <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                </svg>
                                            </div>
                                        </span>
                                        ))}
                                </div>

                                <div>
                                    <button
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        {t('meet.service.button')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1">
                <video
                    className="absolute inset-0 h-full w-full object-cover"
                    autoPlay
                    loop
                    playsInline
                    muted>
                    <source
                        src={dancingVideo}
                        type="video/mp4" />
                </video>
            </div>
        </div>
    )
}
