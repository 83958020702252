import {gql} from "@apollo/client/core";

export const UPDATE_TENANT_CLICKANDMEET_MUTATION = gql`
mutation(
  $tenantId: ID!
  $title: String
  $description: String
  $firstSlot: String
  $lastSlot: String
  $interval: String
  $slots: Int
  $workdays: JSON
  $services: [ID!]
  $members: [ID!]
) {
  updateTenant(
    input: {
      where: { id: $tenantId }
      data: {
        Module: { 
          __typename: ComponentModulClickAndMeet
          Title: $title
          Description: $description
          FirstSlot: $firstSlot
          LastSlot: $lastSlot
          Intervall: $interval
          Slots: $slots
          Workdays: $workdays
          Services: $services
          Members: $members
          Active: true
        }
      }
    }
  ) {
    tenant {
      id
      Key
      Module {
        __typename
        ... on ComponentModulClickAndMeet {
            Title
            Description
            FirstSlot
            LastSlot
            Intervall
            Slots
            Workdays
            Services {
                id
                Name
            }
            Members {
                id
                Name
                Email
                Description
            }
            Active
        }
      }
    }
  }
}`

export const CREATE_SERVICE_CLICKANDMEET_MUTATION = gql`
mutation($name: String) {
  createService(input: { data: { Name: $name } }) {
    service {
      id
      Name
    }
  }
}
`

export const CREATE_MEMBER_CLICKANDMEET_MUTATION = gql`
mutation(
  $name: String
  $email: String
  $services: [ID!]
  $description: String
  $availabilities: JSON
  $notAvailable: JSON
) {
  createMember(
    input: {
      data: {
        Name: $name
        Email: $email
        Services: $services
        Description: $description
        Availabilities: $availabilities
        NotAvailable: $notAvailable
      }
    }
  ) {
    member {
      id
      Name
      Email
      Description
      Availabilities
      NotAvailable
      Services {
        id
      }
    }
  }
}
`

export const QUERY_APPOINTMENTS_BY_TENANT_ID  = gql`
query ($id: ID!) {
  appointments(sort:"Moment:DESC", where: { Tenant: { id: $id } }) {
    id
    Tenant {
      id
    }
    FirstName
    Phone
    LastName
    Moment
    Service {
      Name
    }
    Member {
      Name
    }
    Approved
    created_at
  }
}`

export const QUERY_NEXT_APPOINTMENTS_BY_TENANT_ID = gql `
query ($id: ID!) {
  appointments(limit:1, sort:"Moment:DESC", where: { Approved: true, Tenant: { id: $id } }) {
    id
    Tenant {
      id
    }
    FirstName
    Phone
    LastName
    Moment
    Service {
      Name
    }
    Member {
      Name
    }
    Approved
    created_at
  }
}`
